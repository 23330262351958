@tailwind base;

/* Write your own custom base styles here */

/* Start purging... */
@tailwind components;
/* Stop purging. */

* {
  -webkit-locale: auto;
  white-space: normal;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
}

html,
body {
  @apply bg-gray-50 dark:bg-gray-900;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  scroll-behavior: smooth;
}

#__next {
  min-height: 100vh;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

.stretch-link::after {
  content: '';
  @apply z-10 absolute inset-0 bg-transparent pointer-events-auto;
}

p:empty {
  margin: 0 !important;
  padding: 0 !important;
  height: 0 !important;
}

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

.btn-primary {
  @apply w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 transition-all duration-75;
}

.btn-primary:hover {
  @apply bg-purple-700;
}

.btn-outline {
  @apply flex justify-center w-full rounded-md border border-purple-600 shadow-sm px-4 py-2 bg-white text-sm font-medium text-purple-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-600;
}

.btn-outline:hover {
  @apply bg-gray-50;
}

.btn-primary:focus,
.btn-secondary:focus,
.btn-outline:focus,
.btn-white:focus {
  @apply outline-none ring-2 ring-offset-2 ring-purple-700;
}

.btn-primary[disabled],
.btn-secondary[disabled],
.btn-tertiary[disabled],
.btn-white[disabled],
.btn-outline[disabled],
.btn-red[disabled] {
  opacity: 0.5;
}

.btn-white {
  @apply w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white transition-all duration-75;
}

.btn-white:hover {
  @apply bg-gray-50;
}

.btn-red {
  @apply w-full flex justify-center py-2 px-4 border border-red-500 rounded-md shadow-sm text-sm font-medium text-red-500 bg-white transition-all duration-75;
}

.btn-red:hover {
  @apply bg-gray-50;
}

.btn-secondary {
  @apply w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-700 bg-gray-300 transition-all duration-75;
}

.btn-secondary:hover {
  @apply bg-gray-400 text-gray-900;
}

.btn-tertiary {
  @apply w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-700 bg-blue-400 transition-all duration-75;
}

.btn-tertiary:hover {
  @apply bg-blue-600 text-gray-900;
}

.first-letter::first-letter {
  font-size: 0.5em;
}

/* Accordion styling */

.accordion {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(167, 108, 108, 0.1);
}

.accordion__button {
  background-color: #f4f4f4;
  color: #444;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  border: none;
}

.accordion__button:hover {
  background-color: #ddd;
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 7px;
  width: 7px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  margin-top: 5px;
  padding: 10px;
  animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media print {
  * {
    overflow: visible !important;
  }
  .page {
    page-break-after: always;
  }
}

audio {
  filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%);
  height: 32px;
}

@layer components {
  .panel-primary-button {
    @apply transition-all duration-75 ml-4 inline-flex justify-center font-medium rounded-md border border-transparent bg-purple-600 text-white shadow-sm hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2 py-2 px-3 xl:px-4 text-xs xl:text-sm;
  }

  .panel-secondary-button {
    @apply transition-all rounded-md border border-gray-300 bg-white text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 py-2 px-3 xl:px-4 xl:text-sm;
  }
}

.picker-override {
  background-color: white !important;
  color: rgb(17 24 39) !important;
}
.dark .picker-override {
  background-color: white !important;
  color: rgb(17 24 39) !important;
}

.pickers .picker-override {
  background-color: white !important;
  color: rgb(17 24 39) !important;
}
.pickers .dark .picker-override {
  background-color: white !important;
  color: rgb(17 24 39) !important;
}

.pickers:hover .picker-override:hover {
  background-color: rgb(216, 216, 216) !important;
  color: rgb(17 24 39) !important;
}
.disabled-override {
  background-color: white !important;
  color: rgb(107 114 128) !important;
}
.dark .disabled-override {
  background-color: white !important;
  color: rgb(107 114 128) !important;
}
.pickers .disabled-override {
  background-color: white !important;
  color: rgb(107 114 128) !important;
}
.pickers .dark .disabled-override {
  background-color: white !important;
  color: rgb(107 114 128) !important;
}
.pickers:hover .disabled-override:hover {
  background-color: rgb(216, 216, 216) !important;
  color: rgb(107 114 128) !important;
}
.pickers:hover .dark .disabled-override:hover {
  background-color: rgb(216, 216, 216) !important;
  color: rgb(107 114 128) !important;
}

@media (prefers-color-scheme: dark) {
  .picker-override {
    background-color: white !important;
    color: rgb(17 24 39) !important;
  }
  .dark.picker-override {
    background-color: white !important;
    color: rgb(17 24 39) !important;
  }
  .pickers .picker-override {
    background-color: white !important;
    color: rgb(17 24 39) !important;
  }
  .pickers .dark .picker-override {
    background-color: white !important;
    color: rgb(17 24 39) !important;
  }
  .disabled-override {
    background-color: white !important;
    color: rgb(118, 118, 118) !important;
  }
  .dark .disabled-override {
    background-color: white !important;
    color: rgb(107 114 128) !important;
  }
}

.rotate-animation {
  @apply animate-spin;
  animation: rotate360 1s linear;
}

:root {
  --background: 0 0% 100%;
  --foreground: 240 10% 3.9%;
  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;
  --primary: 182, 85%, 32%;
  --primary-foreground: 355.7 100% 97.3%;
  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;
  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;
  --accent: 240 4.8% 95.9%;
  --accent-foreground: 240 5.9% 10%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;
  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;
  --ring: 182, 85%, 32%;
  --radius: 0.5rem;
}

.dark {
  --background: 240 10% 3.9%;
  --foreground: 0 0% 98%;
  --card: 240 10% 3.9%;
  --card-foreground: 0 0% 98%;
  --popover: 240 10% 3.9%;
  --popover-foreground: 0 0% 98%;
  --primary: 0 0% 98%;
  --primary-foreground: 240 5.9% 10%;
  --secondary: 240 3.7% 15.9%;
  --secondary-foreground: 0 0% 98%;
  --muted: 240 3.7% 15.9%;
  --muted-foreground: 240 5% 64.9%;
  --accent: 240 3.7% 15.9%;
  --accent-foreground: 0 0% 98%;
  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 0 85.7% 97.3%;
  --border: 240 3.7% 15.9%;
  --input: 240 3.7% 15.9%;
  --ring: 240 4.9% 83.9%;
}

/* Basic editor styles */
.tiptap {
  @apply p-6 pt-16 border rounded-md bg-white min-h-64 !m-0 text-slate-700;
}

.tiptap.readonly {
  @apply p-0 border-none min-h-0;
}

.tiptap > * + * {
  margin-top: 0.75em;
}

.tiptap ul,
.tiptap ol {
  padding: 0 1rem;
}

.tiptap ul {
  list-style-type: disc;
}

.tiptap ol {
  list-style-type: decimal;
}

.tiptap li {
  margin: 0.25em 0;
}

.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
  line-height: 1.1;
}

.tiptap h1 {
  @apply text-3xl font-bold;
}

.tiptap h2 {
  @apply text-2xl font-bold;
}

.tiptap h3 {
  @apply text-xl font-bold;
}

.tiptap h4 {
  @apply text-lg font-bold;
}

.tiptap h5 {
  @apply text-base font-bold;
}

.tiptap h6 {
  @apply text-sm font-bold;
}

.tiptap code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

.tiptap pre {
  background: #0d0d0d;
  color: #fff;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.tiptap pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.tiptap img {
  max-width: 100%;
  height: auto;
}

.tiptap blockquote {
  padding-left: 1rem;
  border-left: 2px solid #0d0d0d;
}

.tiptap hr {
  border: none;
  border-top: 2px solid #0d0d0d;
  margin: 2rem 0;
}

.items {
  @apply flex flex-col relative overflow-hidden text-sm shadow rounded bg-white text-slate-800 text-left items-start;
}

.tiptap .item {
  @apply bg-transparent border text-slate-800 rounded block m-0 p-1 text-left w-full;
}

.tiptap .item.is-selected {
  border-color: #000;
}

.tiptap:focus {
  outline: none;
}

.is-active {
  @apply bg-purple-600 text-white rounded;
}

.mention {
  @apply text-purple-700 py-[0.1rem] px-[0.3rem];
}

.tiptap {
  background-color: unset;
}

.tiptap ul[data-type='taskList'] {
  list-style-type: none;
  padding: 0;
}

.tiptap ul[data-type='taskList'] li {
  @apply flex items-center space-x-3;
}

.tiptap ul[data-type='taskList'] li input {
  @apply h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600;
}

.tiptap .is-editor-empty:first-child:before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.editor-container > div:last-of-type {
  @apply flex flex-col flex-1 overflow-y-scroll;
}

.tiptap p > a {
  @apply text-purple-600 underline cursor-pointer;
}

.notification .tiptap h1,
.notification .tiptap h2,
.notification .tiptap h3,
.notification .tiptap h4 {
  @apply text-base;
}

.notification .tiptap h1,
.notification .tiptap h2,
.notification .tiptap h3,
.notification .tiptap h4,
.notification .tiptap h5,
.notification .tiptap h6 {
  @apply font-semibold;
}

.notification .tiptap {
  @apply text-sm;
}

.model-booking-table th {
  @apply bg-purple-50 !important;
}

.model-booking-table td {
  @apply bg-transparent !important;
}

.model-booking-table tr:nth-child(2n + 1):hover {
  @apply bg-white !important;
}

.model-booking-table tr:nth-child(2n) {
  @apply bg-gray-50 !important;
}

.pagination-input {
  margin: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

#proxy-renderer {
  overflow: scroll;
}

.react-pdf__Page__textContent {
  display: none;
}
